/* 
=======================================
Table of Contents
=======================================
*/

@charset 'utf-8';
// ********** FOUNDATION ********** //
@import 'settings';
@import 'foundation';
@include foundation-everything(false);
// ********** MOTION UI ********** //
@import 'motion-ui';
@include motion-ui-transitions;
@include motion-ui-animations;
// ********** SLICK SLIDER ********** //
@import 'slick-theme.scss';
@import 'overrides/slick.scss';
@import 'slick.scss';
// ********** MAGNIFIC POPUP ********** //
@import 'main';
// ********** CART ********** //
@import 'cart/overrides';
// ********** GENERAL ********** //
@import 'general';
@import 'utility';
@import 'header';
@import 'footer';
@import 'fonts';
// ********** HOME ********** //
@import 'home/hero';
@import 'home/vehicle-select';
@import 'home/panels';
@import 'home/featured-wheels';
@import 'home/banners';
@import 'home/about';
@import 'home/services';
@import 'home/instagram-feed';
// ********** MISCELLANEOUS ********** //
@import 'filters';
@import 'vehicle-bar';
@import 'nav-search';
// ********** WHEELS ********** //
@import 'wheel/wheel';
@import 'wheel/stage';
@import 'wheel/specs';
@import 'wheel/info';
@import 'wheel/cta';
@import 'wheel/details-media';
@import 'wheel/view-on-vehicle';
@import 'wheel/vehicle-gallery';
@import 'wheel/spec-tabs';
// ********** TIRES ********** //
@import 'tire/tire';
@import 'tire/info';
@import 'tire/cta';
@import 'tire/specs';
// ********** ACCESSORIES ********** //
@import 'accessory/accessory';
@import 'accessory/cta';
@import 'accessory/bar';
// ********** GALLERY ********** //
@import 'gallery/filter';
@import 'gallery/landing';
@import 'gallery/detail';
// ********** CONTENT ********** //
@import 'content/contact';
@import 'content/page';
@import 'content/news';
// ********** POPUPS ********** //
@import 'popups/package';
@import 'popups/accessory';
@import 'popups/gallery-quote';
@import 'popups/fitment-check';