.cta-buttons {
  margin: 0;
  list-style: none;
  &__btn {
    width: 100%;
    &--space {
      margin-bottom: 1rem !important;
    }
  }
}
