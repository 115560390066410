.services-banner {
  display: grid;
  display: -ms-grid;
  background-color: $black;
  grid-template-columns: 1fr;
  grid-template-rows: 50% 50%;
  -ms-grid-columns: 1fr;
  -ms-grid-rows: 50% 50%;

  @include breakpoint(medium up) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
    -ms-grid-columns: 50% 50%;
    -ms-grid-rows: 1fr;
  }

  &-bkg {
    display: none;

    @include breakpoint(medium up) {
      display: block;
      max-height: 500px;
      grid-column: 1 / 3;
      grid-row: 1 / 2;
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }
  }

  &-left {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    &__btn {
      color: $black;
      background-color: $medium-gray;
      border-radius: 50%;
      height: 70px;
      width: 70px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding-left: 18px;


    }

    @include breakpoint(medium down) {
      text-align: center;
    }
  }

  &-right {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(medium up) {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      -ms-grid-row: 1;
      -ms-grid-column: 2;
    }
  }

  &-header {
    color: $white;
    font-size: 2.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 900;

    @include breakpoint(medium up) {
      margin-top: 0;
      margin-bottom: 2rem;
    }

    @include breakpoint(large up) {
      font-size: 4rem;
    }
  }

  &-txt {
    color: $white;
    font-weight: 300;
    font-size: 1.6rem;
  }
}

.services-list {
  color: $medium-gray;
  list-style: none;
  margin: 0;
  border-top: 1px solid $dark-gray;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  @include breakpoint(medium up) {
    border-bottom: 1px solid $dark-gray;
  }

  li {
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: 1rem;

    @include breakpoint(large up) {
      font-size: 1.4rem;
    }

    .fas {
      color: $primary-color;
      margin-right: 0.5rem;
    }
  }
}