.text-banner {
  padding: 3rem 0;

  &__header {
    color: $dark-gray;
    font-size: 2.7rem;
    font-weight: 700;
  }
}

.text-banner--primary {
  background-color: $primary-color;
  font-size: 1.4rem;
  font-weight: 600;
  color: $white;
  padding: 1rem 0;

  &__header {
    display: inline-block;
    font-size: 1.4rem;
    color: $white;
    margin: 0;
  }
}

.banner-links {
  grid-template-rows: 1fr;
  grid-row-gap: 0.5rem;

  @include breakpoint(large up) {
    grid-template-columns: repeat(2, 50%);
    grid-column-gap: 0.5rem;
  }

  &-container {
    margin-bottom: 0.5rem;

    @include breakpoint(medium up) {
      margin-bottom: 0;

      &.left {
        padding-right: 0.25rem;
      }

      &.right {
        padding-left: 0.25rem;
      }
    }
  }

  img {
    width: 100%;

    @include breakpoint(xlarge up) {}
  }
}