/* ==========================================================================
   1. General (Foundation Overrides)
   ========================================================================== */

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 900;
}

body {
  font-weight: 300;
}

h1,
h2,
h3 {
  color: #000000;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 900;
}

h4,
h5,
h6 {
  font-weight: 700;
  letter-spacing: -1px;
}

h1 {
  font-size: 5.44rem;
}

h2 {
  font-size: 4.18rem;
}

h3 {
  font-size: 3.1rem;
}

h5 {
  font-size: 2.09rem;
}

h6 {
  font-size: 1.15rem;
}

.h1 {
  font-size: 1.5rem;
}

.h2 {
  font-size: 1.8rem;
  font-weight: 700;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  color: #999;
}

body {
  font-family: $body-font-family;
  color: #666;
  font-weight: 300;
  line-height: 1.7;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-primary;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: $black;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 32px;
}

a {
  color: $primary-color;

  &:hover {
    color: #666666;
  }
}

ul,
ol {
  margin-right: 0;
  margin-bottom: 0;
}

table {
  min-width: 100%;
  margin-bottom: 0;
  border: 0;
}

table tr td,
table thead tr th {
  text-align: center;
}

table thead tr th {
  color: #fefefe;
  background: #707070;
}

.bg-img {
  background-color: #333;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.hidden {
  display: none !important;
}

.product-paragraph {
  color: #000;
  margin-bottom: 0.5rem;
  text-align: left;
  margin-right: 1rem;
}

.info {
  &--limit {
    line-height: 1.2em;
    overflow: hidden;
    margin-bottom: 0;
  }

  &--height {
    height: 6em;
  }
}

.parallax {
  position: relative;
  overflow: hidden;
  text-align: center;

  h1,
  h2 {
    position: relative;
    padding: 1.8rem;
    font-size: 2.25rem;
    top: 25%;
  }

  .img {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #333;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .bg-top {
      background-position: center top;
    }
  }

  .img:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    opacity: 0.4;
    background: #000;
  }
}

.white-popup {
  background: $white;
  margin: 20px auto;
  max-width: 400px;
  padding: 20px;
  position: relative;
  width: auto;
  &.processing {
    background: transparent;
    color: white;
    text-align: center;
  }
  .popup-header {
    font-size: 1.2rem;
  }

  .button-group {
    margin-bottom: 0;
    overflow: auto;
  }
}

.no-results {
  color: $black;
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
  color: white;
  display: none;

  &--nav {
    display: block;
    top: 3.2rem;
  }
}

.primary {
  color: $primary-color;
}

.primary-bg {
  background-color: $primary-color;
}

.primary-btn {
  border-radius: 4px;
  padding: 0.8rem 1.9rem;
  margin: 0;
  font-weight: 700;

  a {
    color: $white;
  }
}

.red-bg {
  background-color: $primary-color;
}

.black-font {
  color: #333;
}

.red {
  color: $primary-color;
}

.white {
  color: #fefefe;
}

.button {
  font-weight: bold;
  background-color: $primary-color;
}

.no-margin {
  margin-bottom: 0;
}

.button.secondary {
  background-color: #e7e7e7;
  color: #323232;
}

.black,
.button.black {
  background-color: #000000;
}

.button,
.text-uppercase {
  text-transform: uppercase;
}

.button:hover {
  background-color: #000000;
}

.button-group .button {
  padding-right: 0;
  padding-left: 0;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-bold {
  font-weight: 700;
}

.font-heavy {
  font-weight: 900;
}

.font-italic {
  font-style: italic;
}

.g-recaptcha {
  margin-bottom: 0.5rem;
  display: inline-block;
}

#captchaCheck {
  float: left;
  margin-left: 1rem;
  text-transform: uppercase;
}

.padded {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.padded.extra {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.padded.short {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.padded-left {
  padding-left: 0.5rem;
}

.padded-right {
  padding-right: 0.5rem;
}

.container-padding--b {
  padding-bottom: 4rem;
}

.accordion .accordion-navigation>.content {
  padding-right: 0;
  padding-left: 0;
}

.accordion-item:first-child> :first-child {
  font-weight: 700;
}

.anchor {
  position: relative;
  top: -3rem;
  display: block;
  visibility: hidden;
}

.full-width {
  width: 100%;
  max-width: 100%;
}

.menu.simple>li>a {
  padding: 0;
}

.menu>li:not(.menu-text)>a {
  padding: 0.8rem 1rem;
  font-family: "Lato";
}

.fixed {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
}

.title-bar,
.top-bar {
  padding: 0;
}

.top-bar,
.top-bar ul {
  background-color: transparent;
}

.top-bar ul.is-dropdown-submenu {
  background-color: rgba(255, 255, 255, 1);
  border: 0px;
}

.dropdown.menu .is-dropdown-submenu-parent.is-down-arrow>a::after {
  right: 0px;
}

.dropdown.menu>li.is-dropdown-submenu-parent>a {
  padding-right: 1rem;
}

.dropdown.menu>li.is-dropdown-submenu-parent>a:after {
  border-color: $primary-color transparent transparent;
  right: 0px;
  display: none;
}

.dropdown.menu.vertical>li.opens-right>a::after {
  border-color: transparent transparent transparent $primary-color;
  right: 5px;
}

.dropdown.menu>li>a:hover,
.dropdown.menu>li>ul li a:hover,
.dropdown.menu>li.is-active>a {
  color: $primary-color;
}

.dropdown.menu>li>ul li a {
  padding: 0.5rem;
  white-space: nowrap;
}

.dropdown.menu>li.opens-right>.is-dropdown-submenu {
  top: 154%;
  min-width: 280px;
  padding-bottom: 20px;
}

address {
  font-style: normal;
}

#instafeed .column {
  padding: 1px;
}

@media only screen and (min-width: 40.063em) {
  .int.site-content .h2 {
    font-size: 2.6rem;
  }
}

/********* VIDEO *********/

#videos {
  margin-top: 3rem;

  a:hover {
    color: #666;
  }

  a::before {
    background: rgba(255, 255, 255, 0);
  }

  p {
    font-size: 1.6rem;
  }

  #next-button {
    background-color: #fff;
  }

  #video-container .column a {
    position: relative;
    display: block;
  }
}

// ********** POPUP ********** //
.white-popup--wide {
  max-width: 700px;
}

// ********** HOVER ITEMS ********** //
.hover-item-link {
  display: block;
  padding: 1rem 0;
}

.hover-item {
  position: relative;
  display: block;
  -webkit-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  text-align: center;

  &:hover {
    .hover-item__img {
      opacity: 1;
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
  }

  &__header {
    line-height: 1.2;
    margin-bottom: 0;
    font-size: 1.2rem;
  }

  &__header,
  h6 small {
    color: #545454;
  }

  &__img {
    -webkit-transition: -webkit-all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 2.7rem;
  }

  h2 {
    font-size: 2.1rem;
  }

  h3 {
    font-size: 2rem;
  }

  h5 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }
}

// ********** Product Detail Popup Override ********** //
.popup-bkg .mfp-img {
  background-color: #fff;
}

.popup-bkg .mfp-image-holder .mfp-close {
  color: #000;
  right: 0;
}

// ********** SOCIAL MEDIA ********** //
.icon-container {
  display: flex;

  a {
    color: $white;

    &:not(:first-child) {
      margin-left: 1rem;
    }
  }

  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
  }

  .fb {
    background-color: #3B5998;
  }

  .ig {
    background-color: #4e7ea5;
  }

  .yp {
    background-color: #cf1e1f;
  }

  .yt {
    background-color: #ff0000;
  }
}