.wheel-spec {
    &-header {
        background-color: $primary-color;
        margin-bottom:0;
        font-weight:600;
    }
    &-tabs > a {
        font-size: 1.2rem;
        color: #000;
        &:focus, &[aria-selected=true] {
            color: #fff;
            background-color: #999;
        }
        &:hover {
            color: #000;
        }        
    }
    &-panel {
        padding: 0;
    }
    &-btn {
        color: #c00;
        &:hover {
            color: #999;
        }
    }
}