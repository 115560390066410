.logo {
  display: inline-block;

  img {
    @include breakpoint(medium down) {
      max-width: 180px;
    }
  }
}

.sticky-container {
  z-index: 10;
}

.header {
  background-color: $white;
  background: linear-gradient(-75deg, black 45%, $white 35%, $white 40%);

  @include breakpoint(medium up) {
    background: linear-gradient(-75deg, black 60%, $white 35%, $white 40%);
  }

  &::after {
    position: absolute;
    content: ' ';
    right: 0;
    bottom: -1rem;
    left: 0;
    height: 1rem;
    z-index: 1;
    display: block;
  }

  .top-bar {
    a {
      text-transform: uppercase;
    }
  }

  &--logo {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include breakpoint(large up) {
      justify-content: center;
      align-items: center;
    }
  }
}

.slant {
  position: relative;
  padding-left: 35px;

  @include breakpoint(large up) {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border-top: 105px solid #fff;
      border-right: 35px solid transparent;
    }
  }
}

.hamburger {
  float: right;
  padding: 1rem;

  @include breakpoint(large up) {
    display: none;
  }
}

.top-bar-grid {
  position: relative;
  display: grid;
  grid-template-rows: auto auto;
  background-color: $black;

  @include breakpoint(large up) {
    border-bottom: 2px solid $primary-color;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid #333;
  }
}

.nav-phone {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;

  @include breakpoint(medium down) {
    display: none;
  }

  &__txt {
    line-height: 1;
    margin-right: 0.5rem;
    color: $primary-color;
    font-size: 0.9rem;
    text-transform: uppercase;
    text-align: right;
  }

  &__slash {
    color: $medium-gray;
    letter-spacing: -1px;
  }

  &__number {
    color: $primary-color;
    font-size: 2.5rem;
    line-height: 1;
  }
}

.nav-address {
  font-style: italic;
  font-weight: 500;

  &__title {
    color: $primary-color;
  }

  &__txt {
    color: $medium-gray;
  }
}

.nav-top-links {
  @include breakpoint(medium down) {
    display: none;
  }

  a {
    color: $primary-color;
    font-size: 0.9rem;
    font-weight: 700;
    padding: 0.5rem;
  }

  &__icon {
    font-size: 1.1rem;
    color: $primary-color;
  }
}

.menu {
  @include breakpoint(large up) {
    display: flex;
    justify-content: space-between;
    display: -ms-flexbox;
    display: -webkit-flex;
    -ms-flex-pack: center;
  }

  li {
    margin-right: 1rem;
    display: block !important;

    a {
      display: inline-block;
      width: 100%;
      background-color: $black;
      color: $white;
      font-size: 1.3rem;
      font-weight: 700;
      padding: 0.5rem 1rem;

      @include breakpoint(large up) {
        font-size: 1rem;
        padding: 1rem;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  .nav-submenu {
    list-style: none;
    margin: 0;
    background-color: black;

    @include breakpoint(large up) {
      position: absolute;
      right: auto !important;
      left: 0 !important;
    }

    .is-submenu-item:first-of-type {
      border-top: 2px solid $primary-color;
    }
  }
}

@media screen and (max-width: 1023px) {
  .header {
    padding: 0;
  }

  .menu>li {
    display: inherit;
  }

  .dropdown.menu .submenu {
    position: relative;
    border: 0;
  }
}