.hp-about {
  padding-top: 3rem;
  padding-bottom: 3rem;

  &-header {
    font-size: 2rem;
    color: $medium-gray;
    text-align: center;
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    justify-items: center;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  &-txt {
    font-weight: 400;
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 2rem;
  }

  &-btn {
    color: $white;
    background-color: $primary-color;
    font-size: 1.3rem;
    border-radius: 5px;
    padding: 0.8rem 1.5rem;
  }
}