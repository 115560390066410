.home-panels {
  position: relative;
  overflow: hidden;

  @include breakpoint(small only) {
    margin-bottom: 2rem;
  }

  .column {
    overflow: hidden;
  }

  &__content {
    @include breakpoint(small only) {
      &>.column:not(:last-child) {
        border-bottom: 1px solid $white;
      }
    }
  }

  &__left,
  &__center,
  &__right {
    height: 100px;
    min-height: 200px;
    position: relative;
    overflow: hidden;
    transition: transform 0.2s ease;

    @include breakpoint(medium up) {
      min-height: 500px;
    }

    &:hover {
      transform: scale(1.2);
      transition: transform 0.3s ease;
    }
  }

  &__left {
    background-image: url('/images/home/panel/left.jpg');
    background-size: cover;
  }

  &__center {
    background-image: url('/images/home/panel/center.jpg');
    background-size: cover;

    @media only screen and (min-width: 40rem) {
      border-left: 1px solid $white;
      border-right: 1px solid $white;
    }
  }

  &__right {
    background-image: url('/images/home/panel/right.jpg');
    background-size: cover;
  }
}

.panel {
  display: flex;
  align-items: center;
  justify-content: center;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-pack: center;

  &-link {
    text-align: center;
  }

  &-title {
    color: $white;
    margin-bottom: 0;
    font-weight: 700;
    padding-top: 0.2rem;
    border-bottom: 3px solid $primary-color;

    @include breakpoint(medium down) {
      font-size: 2rem;
    }
  }

  &-subtitle {
    border-top: 3px solid $primary-color;
    color: $white;
    font-size: 1.2rem;
    font-weight: 400;
    padding-top: 0.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.angled {
  position: absolute;
  width: 50%;
  border-top: 10px solid $primary-color;
  bottom: -50px;
  height: 120px;
  background: #fefefe;

  @include breakpoint(small down) {
    display: none;
  }

  &.left {
    left: 0;
    transform: skewY(4.5deg);
  }

  &.right {
    right: 0;
    transform: skewY(-4.5deg);
  }
}