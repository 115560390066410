.featured-wheels {
  margin-bottom: 2rem;

  &-header {
    position: relative;
    text-align: center;
    font-size: 1.5rem;
    padding-bottom: 0.5rem;

    @include breakpoint(large up) {
      font-size: 3.5rem;
    }

    &:after {
      content: '';
      position: absolute;
      border-bottom: 1px solid #ccc;
      bottom: 0;
      width: 60%;
      left: 50%;
      transform: translateX(-50%);

      @include breakpoint(large up) {
        width: 40%;
      }
    }
  }

  &-container {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    justify-items: center;

    @include breakpoint(large up) {
      grid-template-columns: repeat(5, 20%);
    }
  }
}