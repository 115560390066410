.accordion-filter {
  text-align: center;
  margin-right: 1rem;
  margin-left: 1rem;
  @include breakpoint(medium up) {
    margin-right: auto;
    margin-left: auto;
  }
  &__container {
    @include breakpoint(large up) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__header {
    font-size: 1rem;
    display: inline-block;
    margin: 0;
    &--mobile {
      border: 1px solid $light-gray;
      padding: 1rem;
      text-transform: capitalize;
      font-size: 1rem;
      color: $black;
    }
  }
  &__content {
    border: 0;
    @include breakpoint (small only) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__dropdown {
    @include breakpoint (large up) {
      margin: 0 0.5rem;
      flex-grow: 1;
    }
    select {
      padding: 1rem;
      height: auto;
      background-position: right 0rem center;
      @include breakpoint(medium up) {
        padding: 0.5rem;
      }
      @include breakpoint(large up) {
        margin: 0;
      }
    }
  }
  &__btn {
    margin: 0;
    padding: .65rem 1rem;
    font-size: 1rem;
    @include breakpoint (small only) {
      max-width: none;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
}