.vehicle-select {
  background-color: $white;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;

    @include breakpoint(large) {
      padding: 1rem 0;
      border-bottom: 1px solid $light-gray;
    }
  }

  &__header {
    font-size: 2rem;
    font-weight: 900;
    margin: 0;
  }

  &__icon {
    color: $primary-color;
  }

  &__links {
    margin-bottom: 1rem;

    a {
      color: $primary-color;
      font-size: 0.9rem;
      font-weight: 500;
      font-style: italic;
      text-transform: uppercase;
      text-decoration: underline;

      @include breakpoint(large) {
        font-size: 1rem;
      }
    }
  }

  &__bottom {
    padding-top: 1rem;
    font-weight: 700;

    @include breakpoint(large) {
      padding: 1rem 0;
    }
  }

  .select {
    border: 1px solid #ccc;

    &:disabled {
      background-color: #ddd;
    }
  }
}

.vehicle-select-columns #app {
  display: flex;
  justify-content: space-between;

  label {
    flex-grow: 1;
    margin: 0.5rem;
  }
}

.vehicle-select-horizontal {
  display: block;
}
