.home-slider-container {
  margin-bottom: 0;
  .slick-slide {
    position: relative;
  }
}

.home-slider-img {
  width: 100%;
}

.hero {
  position: relative;
  &-link {
    display: block;
  }
}

.hero-bkg {
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  @include breakpoint(medium up) {
    background-size: cover;
  }
}

.hero-overlay {
  background-color: $primary-color;
  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width: 1023px) {
      align-items: center;
      text-align: center;
    }
  }
  @include breakpoint(large up) {
    position: absolute;
    top: 20%;
    right: 0;
    padding: 0;
    background-color: transparent;
  }
  &-info {
    display: inline-block;
    background-color: rgba(0, 102, 178, 1);
    padding: 0.5rem;
    color: $white;
    max-width: 500px;
    width: 100%;
    @include breakpoint(medium up) {
      padding: 1rem 1rem 1rem 2rem;
    }
    @include breakpoint(large up) {
      margin: 0 0 1rem;
      background-color: rgba(0, 102, 178, 0.8);
      clip-path: polygon(6% 0, 100% 0%, 100% 100%, 0 100%);
      -webkit-clip-path: polygon(6% 0, 100% 0%, 100% 100%, 0 100%);
    }
    &--small {
      font-size: 1rem;
      font-weight: 400;
      margin: 0;
      @include breakpoint(medium up) {
        font-size: 1.43rem;
      }
    }
    &--medium {
      font-size: 1.7rem;
      font-style: italic;
      @include breakpoint(medium up) {
        font-size: 2.4rem;
      }
    }
    &--large {
      color: $white;
      font-size: 2rem;
      font-style: italic;
      margin: 0;
      @include breakpoint(medium up) {
        font-size: 4.6rem;
      }
    }
  }
  &-header {
    font-family: 'Roboto Condensed';
    text-transform: capitalize;
    font-style: italic;
    font-size: 2rem;
    margin-bottom: 0;
    @include breakpoint(large up) {
      margin-top: 1rem;
      font-size: 3.2rem;
      margin-bottom: 0.5rem;
    }
  }
  &-desc {
    text-transform: capitalize;
    font-weight: 300;
    line-height: 1.2;
    font-size: 2rem;
    font-style: italic;
    margin-bottom: 0;
    @include breakpoint(large up) {
      margin-bottom: 1rem;
    }
  }
}

.hero-btn {
  align-self: flex-start;
  @include breakpoint(medium up) {
    display: inline-block;
  }
  @media (max-width: 1023px) {
    margin: 0 auto 1rem;
  }
}