.page {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        text-transform: initial;
        margin-bottom: 0.5rem;
    }

    &__content {
        padding-top: 50px;
    }

    h1 {
        font-size: 57px;
        line-height: 0.719;
    }

    h2 {
        font-size: 38px;
        line-height: 1.079;
    }

    h3 {
        font-size: 32px;
        line-height: 1.281;
    }

    h4 {
        font-size: 25px;
        line-height: 1.64;
    }

    h5 {
        font-size: 22px;
        line-height: 1.864;
    }

    p {
        font-size: 16px;
    }

    .button a {
        color: $white;
    }
}

.page-header {
    font-size: 2.5rem;
    font-weight: 700;
    font-family: "Roboto Condensed";
}