#gallery {
	.hover-list {
		.photo {
			position: relative;
			display: block;
			padding: 0 1px 1px 0;

			&-image {
				position: absolute;
				bottom: 0;
				right: 0;

				&-container {
					height: 0;
					padding-top: 50%;
					overflow: hidden;
					position: relative;
				}
			}

			&-overlay {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: rgba(0, 0, 0, 0.7);
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				opacity: 0;
				color: $white;
				font-size: 1.25rem;
				font-weight: 900;
				transition: all .3s ease-in-out;
				z-index: 1;

				@include breakpoint (small only) {
					font-size: .7rem;
				}

				>div {
					padding: .25rem 0;
					line-height: 1;
					text-align: center;

					&.photo-overlay-divider {
						padding: 1rem 0;
					}
				}

				&-accessory,
				&-offset,
				&-suspension {
					font-size: 1.5rem;

					@include breakpoint (small only) {
						font-size: .9rem;
					}
				}

				&-divider {
					height: 4px;
					width: 20%;
					position: relative;

					&:after {
						content: '';
						background-color: $secondary-color;
						height: 4px;
						position: absolute;
						top: 43%;
						bottom: 0;
						left: 0;
						right: 0;
					}
				}

				&-moreinfo {
					font-size: 1rem;
					text-transform: uppercase;
				}
			}

			&:hover {
				.photo-overlay {
					opacity: 1;
				}
			}
		}

		.video:after {
			font-weight: 300;
			color: #fefefe;
		}

		.video:hover:before {
			background: rgba(223, 61, 61, .9);
		}
	}
}