#wheel {
  .wheel-sizes {
    margin-top: 1rem;
    span {
      font-size: 1rem;
      font-weight: bold;
    }
  }
  .wheel-details {
    background: #f1f1f1;
    padding: 1rem 1.5rem;
    .wheel-paragraph {
      font-size: 1rem;
      font-weight: bold;
      text-align: left;
      margin-bottom: 1rem;
      span {
        font-size: 1.2rem;
      }
    }
  }
  .loadingImage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
  }
}

.gallery-popup {
  display: table;
  margin: 0 auto;
  .galleryView-info {
    color: #ccc;
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 1rem;
    span {
      display: block;
    }
  }
  .popup-car {
    font-weight: 900;
    font-size: 1.3rem;
    color: #fff;
  }
  .mfp-close {
    color: white !important;
    position: relative;
    float: right;
  }
}

@include breakpoint(medium up) {
  .accordion-content {
    padding: 0;
    border: 0;
    border-bottom: 0 !important;
  }
  .alt-images {
    .slick-slide {
      width: 100% !important;
    }
  }
}

.detail-thumb {
  p {
    line-height: 1;
    margin: 0.25rem;
  }
  .finish-name {
    color: $dark-gray;
    font-size: 0.8rem;
  }
  img {
    transition: transform 0.3s ease-in-out;
    &:hover {
      backface-visibility: hidden;
      transform: scale(1.08);
    }
  }
}

#specs {
  margin-bottom: 1.25rem;
  overflow: auto;
}

.see-on-vehicle-container {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 0.2rem;
  margin: 0.5rem 0;
}

.see-on-vehicle-header {
  font-size: 1.5rem;
  margin-bottom: 0;
}

.see-on-vehicle-select {
  margin-bottom: 0;
}

table {
  border: 0;
  margin-bottom: 0;
  tr {
    td,
    th {
      text-align: center;
    }
    th {
      background: $light-gray;
    }
  }
}

@include breakpoint(small only) {
  #wheel {
    .banner-top {
      .wheel-banner {
        height: 6rem;
      }
      .heading {
        font-size: 1.7rem;
        top: 90%;
      }
    }
    .slick-slide {
      display: inline-block;
      float: none;
    }
  }
}

@include breakpoint(medium up) {
  .finish-header {
    display: none;
  }
}