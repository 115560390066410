footer {
  .ft-bottom .divider {
    padding: 0 25px;
  }

  .ft-bottom p,
  .ft-bottom .divider {
    display: inline-block;
  }

  .ft-bottom {
    font-weight: 500;
    color: #ffffff;
    background: #050505;
    text-align: center;

    p {
      line-height: 7.18rem;
    }
  }
}

@media screen and (max-width: 480px) {
  footer {
    .ft-bottom {
      text-align: center;
      padding: 10px 0;

      .divider {
        display: none;
      }

      p {
        line-height: 1rem;
        font-size: 0.78rem;
        margin-bottom: 0;

        span {
          font-size: 0.9rem;
        }
      }
    }
  }
}

.bg-black {
  background-color: $black;
}

.footer-info {
  color: $medium-gray;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding-top: 2.5rem;
  padding-bottom: 2rem;
  border-top: 10px solid $primary-color;
  border-bottom: 1px solid #666;

  .footer-logo {
    margin-bottom: 1.5rem;
  }

  h3 {
    color: $primary-color;
    font-family: $font-primary;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.2rem;
  }

  .ft-quick-links {
    ul {
      margin: 0;
      list-style: none;
      line-height: 1;

      li {
        &:not(.menu-text)>a {
          padding: 0.3rem 0;
        }
      }

      a {
        display: block;
        color: $medium-gray;
        padding-left: 0;
        font-family: $font-primary;
        font-weight: 500;
        font-size: 0.8rem;
        text-transform: uppercase;

        &:hover {
          color: white;
        }
      }
    }
  }

  .contact {
    margin-bottom: 1rem;
  }

  .hours {
    position: relative;
    line-height: 1.4;
  }
}

.vcard {
  margin-bottom: 1rem;

  p {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 1rem;
    margin: 0;
    line-height: 1.4rem;
  }

  p.fn,
  p.tel {
    position: relative;

  }
}

.ft-column {
  padding-top: 1.5rem;
}

.footer-legal {
  background-color: $black;
  color: $medium-gray;
  padding: 1.2rem 0;
  text-align: center;

  .copyright {
    max-width: 800px;
    margin: 0 auto;
  }

  p {
    font-size: 0.87rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: 0;
  }
}